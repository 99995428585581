import router from "@/router/index.js";
import { loginQuit, requestTicket } from "@/utils/api";

// 获取url中的get参数
export function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return decodeURI(r[2]);
  return null; //返回参数值
}
// 获取url中的path参数
export function getPathVariableAppKey() {
  return router.currentRoute.value.params.appkey;
}
// 获取url中的特定参数
export function getPathVariable(key) {
  return router.currentRoute.value.params[key];
}
// 是否是url
export function isUrl(url) {
  if (!url) {
    return false;
  }
  var strRegex = /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i;
  var re = new RegExp(strRegex);
  return re.test(url);
}
// 钉钉扫码增加页面监听
export function addListener(returnUrl) {
  // returnUrl="https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingoalfufy8agasxdno0w&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=http%3A%2F%2Fstaff.beizeng.local%2Fcallback"
  var handleMessage = function(event) {
    var origin = event.origin;
    if (origin == "https://login.dingtalk.com") {
      //判断是否来自ddLogin扫码事件。
      var loginTmpCode = event.data;
      //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
      window.location.href = returnUrl + "&loginTmpCode=" + loginTmpCode;
      // api.loginByTempCode(loginTmpCode).then(res=>{
      //     console.log(res)
      // }).catch(err=>{
      //   console.log(err)
      // })
    }
  };
  if (typeof window.addEventListener != "undefined") {
    window.addEventListener("message", handleMessage, false);
  } else if (typeof window.attachEvent != "undefined") {
    window.attachEvent("onmessage", handleMessage);
  }
}
// 返回首页
export function homePage() {
  router.push("/");
}
// 显示首页二维码
export function showLogin() {
  let appId = process.env.VUE_APP_DING_APP_ID;
  let dingUrl =
    "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=" + appId;
  let realUrl = getUrlParam("returnUrl");
  realUrl =
    process.env.VUE_APP_DING_CALLBACK_URL +
    (realUrl ? "?returnUrl=" + realUrl : "");
  realUrl = encodeURIComponent(realUrl);
  //拼接url参数
  let returnUrl =
    dingUrl +
    "&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=" +
    realUrl;
  // 监听页面并获取loginTmpCode
  addListener(returnUrl);
  // 判断是否在钉钉容器内打开网页
  if (window.dd.env.platform == "notInDingTalk") {
    // 将goto参数urlencode编码
    var goto = encodeURIComponent(returnUrl);
    window.DDLogin({
      id: "login_container",
      goto: goto,
      style: "border:none;background-color:#FFFFFF;",
      width: "365",
      height: "365",
    });
  } else {
    var corpId = process.env.VUE_APP_DING_CORP_ID;
    // 获取微应用免登授权码
    window.dd.ready(function() {
      window.dd.runtime.permission.requestAuthCode({
        corpId: corpId, // 企业id
        onSuccess: function(info) {
          var code = info.code; // 通过该免登授权码可以获取用户身份
          console.log(code);
        },
      });
    });
  }
}
// 获取ticket后执行
export function handleTicket() {
  let returnUrl = getUrlParam("returnUrl");
  if (returnUrl) {
    requestTicket().then((res) => {
      returnUrl = decodeURIComponent(returnUrl);
      returnUrl =
        returnUrl +
        (returnUrl.indexOf("?") > -1 ? "&" : "?") +
        "ticket=" +
        res.data;
      window.location.href = returnUrl;
    });
  } else {
    let chref = router.currentRoute.value.path;
    if (chref && (chref === "/callback" || chref === "/")) {
      // router.push('/pages/info')
      // 此处为了测试页面，更新为home页面跳转
      router.push("/home");
      // router.push("/admin");
    }
  }
}
// 缓存用户信息
export function cacheLoginUser(ur) {
  if (ur.userInfo) {
    window.localStorage.setItem("userInfo", JSON.stringify(ur.userInfo));
    window.userInfo = ur.userInfo;
  }
  if (ur.token) {
    window.localStorage.setItem("token", ur.token);
  }
  handleTicket(ur.ticket);
}
// 获取用户信息
export function getUserInfo() {
  let usObj = window.localStorage.getItem("userInfo");
  if (usObj) {
    return JSON.parse(usObj);
  }
  return null;
}
// 校验用户角色
export function checkUserRole(role) {
  let userInfo = getUserInfo();
  return (
    userInfo &&
    userInfo.permissions &&
    Array.isArray(userInfo.permissions) &&
    userInfo.permissions.includes(role)
  );
}
// 退出
export function quit() {
  loginQuit().then((res) => {
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("token");
    homePage();
  });
}
