<template>
  <div class="login_wrapper">
    <div class="login_form">
      <!-- 左侧 -->
      <div class="left_wrap">
        <div class="logo">
          <img src="../assets/beizengLogo.png" alt="" />
        </div>
        <!-- 语言切换 -->
        <div class="lang">
          <p>English</p>
          <i></i>
          <p>中文</p>
        </div>
        <!-- 版权信息 -->
        <div class="copy">&copy;倍增智能科技 版权所有 <a href="https://www.miit.gov.cn/" target="_blank">沪ICP备17046381号-9</a></div>
      </div>
      <!-- 右侧登录 -->
      <div class="right_wrap">
        <!-- 标题 -->
        <div class="title">
          <p>{{ loginIndex === 1 ? "扫码登录" : "账户登录" }}</p>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!--提示描述 -->
          <div class="desc">
            <img src="../assets/dingLogo.png" alt="" />
            <p>打开<span>手机钉钉</span>扫一扫登录</p>
          </div>
          <!-- 钉钉二维码区域 -->
          <div class="dingdingCode">
            <div id="login_container" v-loading="loading"></div>
          </div>
          <!-- 底部登录模式 -->
          <div class="mode">
            <img src="../assets/lockLogo.png" alt="" />
            <p>账号密码登录</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { handleTicket, showLogin, getUrlParam } from "@/utils/tools";
import { requestUser } from "@/utils/api";
import { ref, reactive, toRefs, onMounted } from "vue";
export default {
  name: "Login",
  components: {},
  setup() {
    const data = reactive({
      loginIndex: 1, // 登录方式
      loading: true,
    });
    onMounted(() => {
      requestUser()
        .then((res) => {
          console.log("requestUser:", res);
          handleTicket();
        })
        .catch((err) => {
          console.log("requestUser:", err);
          //手动模拟：扫码登陆后获取的用户信息
          let obj = {
            // token手动从测试环境接口中获取
            token: "4bf47368b70b4bf995e63ac8db0e79f3",
            userInfo: {
              account: "18502789940",
              avatar: "",
              depts: [
                {
                  key: "435971149",
                  value: "IT技术四部",
                },
              ],
              email: "chenhang@beizengkj.com",
              json: null,
              name: "陈航",
              permissions: ["0"],
              token: "4bf47368b70b4bf995e63ac8db0e79f3",
              userId: "19174255471225954",
            },
          };
          window.localStorage.setItem("token", obj.token);
          window.localStorage.setItem("userInfo", JSON.stringify(obj.userInfo));
          // 显示钉钉二维码登录入口
          showLogin();
        });
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style>
/* body {
  background-image: url("/bg.jpg");
  background-position: center;
  background-size: cover;
}
#login_container {
  position: relative;
  top: 20%;
  text-align: center;
} */
</style>

<style lang="scss" scoped>
@import "../styles/mixin.scss";
@media screen and (max-width: 1280px) {
  #login_container {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 1281px) {
  #login_container {
    transform: scale(0.9);
  }
}
.login_wrapper {
  @include position(fixed, 0, 0, 0, 0, 100%, 100%);
  background-image: url("../assets/background.png");
  background-position: center;
  background-size: cover;
  .login_form {
    display: flex;
    @include position(fixed, 50%, 0, 0, 50%, 1200px, 728px);
    transform: translate(-50%, -50%);
    background: transparent;
    border-radius: 0px 10px 10px 0px;
    .left_wrap {
      position: relative;
      width: 521px;
      height: 728px;
      background: #3d8fff;
      opacity: 0.9;
      border-radius: 10px 0px 0px 10px;
      .logo {
        position: absolute;
        width: 339px;
        height: 79px;
        top: 95px;
        left: calc(50% - 170px);
        img {
          display: block;
          height: 100%;
        }
      }
      .lang {
        position: relative;
        top: 546px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        p {
          position: relative;
          font-size: 19px;
          font-family: "SourceRegular";
          font-weight: 400;
          color: #fff;
          width: 50%;
          margin: 0 22px;
          &:hover {
            color: #dedede;
            cursor: pointer;
          }
          &:first-child {
            text-align: right;
          }
          &:last-child {
            text-align: left;
          }
        }
        i {
          display: inline-block;
          background-color: #fff;
          @include positionTranslate(absolute, 50%, 50%, 1px, 12px);
        }
      }
      .copy {
        position: absolute;
        width: 100%;
        bottom: 58px;
        text-align: center;
        font-size: 15px;
        font-family: "SourceNormal";
        font-weight: 400;
        color: #fff;
        line-height: 23px;
        opacity: 0.8;
        a{
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .right_wrap {
      position: relative;
      width: calc(1200px - 521px);
      height: 728px;
      background: #fff;
      border-radius: 0px 10px 10px 0px;
      .title {
        width: 100%;
        height: 118px;
        border-bottom: 1px solid #ededed;
        p {
          text-align: center;
          line-height: 118px;
          font-size: 36px;
          font-family: "SourceMedium";
          font-weight: bold;
          color: #3d8fff;
        }
      }
      .content {
        position: relative;
        width: 100%;
        height: calc(728px - 118px);
        // 描述文字
        .desc {
          @include positionTranslate(absolute, 39px, 50%, 251px, 39px, -50%, 0);
          @include flex(row, center, center);
          background: #f5f5f5;
          border-radius: 20px;
          p {
            font-family: "SourceRegular";
            font-size: 16px;
            font-weight: 400;
            color: #9a9a9a;
            line-height: 59px;
            span {
              color: #3d8fff;
            }
          }
          img {
            width: 26px;
            height: 26px;
            margin-right: 8px;
          }
        }
        // 钉钉二维码模块
        .dingdingCode {
          @include positionTranslate(absolute, 50%, 50%, 289px, 289px);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        // 底部登录模式
        .mode {
          @include positionTranslate(
            absolute,
            520px,
            50%,
            170px,
            40px,
            -50%,
            0
          );
          @include flex(row, center, center);
          &::before {
            position: absolute;
            left: -100px;
            content: "";
            width: 77px;
            height: 1px;
            background: #d3d3d3;
            border-radius: 1px;
            display: block;
          }
          img {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
          p {
            font-family: "SourceRegular";
            font-size: 22px;
            font-weight: 400;
            color: #555555;
            line-height: 59px;
          }

          &::after {
            position: absolute;
            right: -100px;
            content: "";
            width: 77px;
            height: 1px;
            background: #d3d3d3;
            border-radius: 1px;
            display: block;
          }
        }
      }
    }
  }
}
</style>
